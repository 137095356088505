<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Accounting Report</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateFrom"
                                >
                                </date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>                            
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%;"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>

                <hr>
                <div class="col-xs-6 text-light">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>
                <hr>

                <accounting-report-grid ref="grid" :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" :dateTo="DateToChangest" :dateFrom="DateFromChangest" />
                <accounting-report-form ref="AccountingReportModal" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import accountingReportServices from './Script/AccountingReportServices';
import accountingReportGrid from './Grid/AccountingReportGrid.vue';
import accountingReportForm from './Component/AccountingReportForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';
import { report, datetime_zone } from '../../../infrastructure/constant/variable';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import datePicker from 'vue2-datepicker';

export default {
    name: 'AccountingReport',
    components: {
        'accounting-report-grid': accountingReportGrid,
        'accounting-report-form': accountingReportForm,
        'date-picker': datePicker
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Accounting Report');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,

            DateFrom: report.default_start_date,
            DateTo: report.default_end_date,

            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,

            DateTypeData: [],
            DateType: report.default_date,
        }
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();

        this.onChangeDateType();
    },
    methods: {
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);

            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;

            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;

            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if ((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0) {
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else {
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;

                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo() {
            var vue = this;
            if ((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0) {
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else {
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        addClick(){
            this.$refs.AccountingReportModal.addClick();        
        },
        editClick(data, view){
            this.$refs.AccountingReportModal.editClick(data, view);        
        },
        deleteClick(data){
            this.$swal(sweet_alert.delete).then((result) => {
                const accountingReportData = {
                    status: "Delete",
                    reporting_date: data.reporting_date,
                    attachments: null,
                    notes: data.notes
                }

                const variables = {
                    id: parseInt(data.accounting_report_id),
                    data : accountingReportData
                }

                if (result.isConfirmed == true) {
                    accountingReportServices.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.errorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>