<template>
    <div>
        <div class="modal fade" id="AccountingReportModal" tabindex="-1" aria-labelledby="AccountingReportModalLabel" aria-hidden="true" style="overflow:auto;">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="AccountingReportModalLabel" class="font-weight-bold">{{ModalTitle}} Accounting Report :</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Tanggal</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <date-picker
                                        v-model="AccountingReportDate"
                                        format="DD MMM YYYY HH:mm:ss"
                                        type="datetime"
                                        placeholder=""
                                        value-type="YYYY-MM-DD HH:mm:ss"
                                        style="width:100%"
                                    ></date-picker>
                                    <label id="errorAccountingReportDate" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                              
                            </CRow>
                            <CRow class="pt-3">
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                              
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Catatan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <textarea class="form-control" rows="2" v-model="Notes"></textarea>
                                    <label id="errorNotes" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                              
                            </CRow>
                            <CRow class="pt-3">
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Attachment</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInputFile type="file" id="File" multiple @input="onChangeFile()"/>
                                    <label id="errorFile" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                              
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold ">&nbsp;</label>
                                    <label class="form-label font-weight-bold" style="float:right">&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <div v-if="this.FileStatus == 'Uploaded'">
                                        <div v-for="(link, index) in Files" :key="index">
                                            <a href="#" @click="fileClick(link.value)">{{ link.value }}</a>
                                        </div>
                                    </div>
                                    <div v-if="this.FileStatus == 'New'">
                                        <div v-for="(link, index) in Files" :key="index">
                                            <label>{{ link.value }}</label>
                                        </div>
                                    </div>
                                </CCol>                              
                            </CRow>

                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <file-viewer ref="fileViewer"/>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { response } from '../../../../infrastructure/constant/response';
import accountingReportServices from '../Script/AccountingReportServices';
import moment from 'moment';
import FileViewer from '../../../../shared/FileViewer.vue';

export default {
    name: 'AccountingReportForm',
    components: {
        'date-picker' : datePicker,
        'file-viewer': FileViewer,
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle: null,

            AccountingReportId: null,
            AccountingReportDate: null,
            Status: null,
            StatusData: [],
            Files: [],
            FileStatus: null,
            Notes: null,

            SaveType: null,
            Error: 0,
        }
    },
    methods: {
        onChangeFile(){
            this.Files = [];
            var data = document.getElementById('File').files;

            for (var i = 0; i < data.length; ++i) {
                this.Files.push({value : data[i].name})
            };
        },
        editFile(data){
            for (var i = 0; i < data.length; ++i) {
                this.Files.push({value : data[i]})
            };
        },
        fileClick(fileName){
            this.$refs.fileViewer.viewClick(fileName);
        },
        async addClick() {
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Add';

            this.AccountingReportId = null;
            var dateNow = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            this.AccountingReportDate = dateNow;
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            //File
            this.Files = [];
            this.FileStatus = 'New';
            document.getElementById('File').value= null;

            this.Notes = null;

            this.SaveType = 'Add';
            
            $(".save-button").show();
            window.$('#AccountingReportModal').modal('show');
        },
        async editClick(data, View){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Edit';
            
            this.AccountingReportId = data.accounting_report_id;
            this.AccountingReportDate = data.reporting_date;
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_accounting_report');
            this.Status = data.status;

            //File
            this.Files = [];
            this.FileStatus = 'Uploaded';
            document.getElementById('File').value= null;
            this.editFile(data.attachments);

            this.Notes = data.notes;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View';
            }
            else {
                $(".save-button").show();
            }
            window.$('#AccountingReportModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            
            if(this.AccountingReportDate == '' || this.AccountingReportDate == null){
                this.errorShow('errorAccountingReportDate');
            }
        },
        async saveClick() {
            this.inputValidation();

            //proses
            if(this.Error == 0){
                var file = null;
                var fileData = document.getElementById('File').files;
                if(fileData.length > 0){
                    file = fileData;
                }

                const accountingReportData = {
                    status: this.Status,
                    reporting_date: this.AccountingReportDate,
                    attachments: file,
                    notes: this.Notes
                }

                if (this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : accountingReportData
                    }

                    accountingReportServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#AccountingReportModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                } 
                else if (this.SaveType == 'Edit'){
                    this.$loading(true);
                    
                    const variables = {
                        id: parseInt(this.AccountingReportId),
                        data : accountingReportData
                    }
                    
                    accountingReportServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#AccountingReportModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            this.Error++;
        },
    }
}
</script>