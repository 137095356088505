<template>
    <div>
        <kendo-grid
            ref="grid" 
            :data-source="AccountingReport"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import accountingReportServices from '../Script/AccountingReportServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import moment from 'moment';

export default {
    name: 'AccountingReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['deleteClick','editClick','dateTo', 'dateFrom'],
    computed: {
        AccountingReport(){
            var dateFrom = moment(this.$props.dateFrom).startOf('day').format("YYYY-MM-DD HH:mm:ss");
            var dateTo = moment(this.$props.dateTo).startOf('day').format("YYYY-MM-DD HH:mm:ss");

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom,
                                endDate: dateTo
                            };
                            return { 
                                query: accountingReportServices.readAccountingReportQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetAccountingReports == null){
                            return [];
                        }else{
                            return response.data.GetAccountingReports;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetAccountingReports == null){
                            return 0;
                        }else{
                            return response.data.GetAccountingReports.length;
                        }
                    },
                },
                sort: { field: "created_at", dir: "desc" }
            });
        }
    },
    data: function () {
        return  {
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "reporting_date", width: 200, title: "Tanggal Dibuat", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                template: "#= kendo.toString(new Date(reporting_date), 'yyyy-MM-dd') #", attributes:{style: "text-align:center;"} },  
                { field: "created_by", title: "Dibuat Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", width: 200, title: "Tanggal Dibuat", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #", attributes:{style: "text-align:center;"} },  
                { field: "approved_by", title: "Approve Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "approved_date", width: 200, title: "Tanggal Approve", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #", attributes:{style: "text-align:center;"} },  
                { field: "notes", title: "Catatan", width: 400, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }         
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
        };
    },
    mounted: async function() {
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Accounting Report")
        },
    },
}
</script>