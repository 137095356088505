<template>
    <div>
        <div class="modal fade" id="assetModal" tabindex="-1" aria-labelledby="assetModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="assetModalLabel" class="font-weight-bold">{{ModalTitle}}{{AssetName}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold">Tipe Aktifa</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="AssetType" class="font-weight-bold" :options="AssetTypeData" :value.sync="AssetType" />
                                    <label id="errorAssetType" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="AssetStatus" class="font-weight-bold" :options="AssetStatusData" :value.sync="AssetStatus" />
                                    <label id="errorAssetStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nama</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="AssetName" v-model="AssetName" class="font-weight-bold" />
                                    <label id="errorAssetName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow >
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Tanggal Pembelian</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <date-picker
                                        v-model="AssetPurchaseDate"
                                        format="DD MMM YYYY"
                                        type="date"
                                        placeholder="Select date"
                                        value-type="YYYY-MM-DD"
                                        style="width:100%"
                                    ></date-picker>
                                    <label id="errorAssetPurchaseDate" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow class="pt-3">
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Harga Pembelian</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="AssetPurchaseValue" type="number" v-model="AssetPurchaseValue" class="font-weight-bold" />
                                    <label id="errorAssetPurchaseValue" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nilai Aktifa</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="AssetValue" type="number" v-model="AssetValue" class="font-weight-bold" />
                                    <label id="errorAssetValue" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold">Depresiasi</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <input type="checkbox" v-model="Depreciable" @change="onChangeDepreciable()"/>
                                    <label id="errorDepreciable" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow class="pt-2">
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Periode Depresiasi</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="DepreciationPeriod" type="number" v-model="DepreciationPeriod" class="font-weight-bold" />
                                    <label id="errorDepreciationPeriod" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Persentase Depresiasi Tahunan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="DepreciationPercentageYearly" type="number" v-model="DepreciationPercentageYearly" class="font-weight-bold" />
                                    <label id="errorDepreciationPercentageYearly" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import assetService from '../Script/AssetService.js';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
    name: 'AssetForm',
    props: ['reload'],  
    components: {
        'date-picker' : datePicker,
    },
    data: function () {
        return {
            ModalTitle : '',
            AssetId:'',
            AssetName: '',
            AssetType: '',
            AssetTypeData: [],
            AssetStatus: '',
            AssetStatusData: [],
            AssetPurchaseDate: '',
            AssetPurchaseValue: 0,
            AssetValue: 0,
            Depreciable: false,
            DepreciationPeriod: 0,
            DepreciationPercentageYearly: 0,
            Error: 0,
            SaveType: '',
        }
    },
    methods: {
        onChangeDepreciable(){
            if(this.Depreciable){
                document.getElementById('DepreciationPeriod').readOnly = false;
                document.getElementById('DepreciationPercentageYearly').readOnly = false;
            }else{
                document.getElementById('DepreciationPeriod').readOnly = true;
                document.getElementById('DepreciationPercentageYearly').readOnly = true;
            }
        },
        async addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Add Aktifa: ';       

            this.AssetId = null;
            this.AssetName = '';
            this.AssetType = 1;
            this.AssetTypeData = await this.$globalfunc.globalTypeLookupDropdown("daftar_aktiva");
            this.AssetStatus = 1;
            this.AssetStatusData = await this.$globalfunc.globalStatusLookupDropdown("active_not_active");
            this.AssetPurchaseDate = '';
            this.AssetPurchaseValue = 0;
            this.AssetValue = 0;
            this.Depreciable = false,
            this.DepreciationPeriod = 0;
            this.DepreciationPercentageYearly = 0;

            this.onChangeDepreciable();

            document.getElementById('AssetStatus').disabled = true;

            this.SaveType = 'Add';
            window.$('#assetModal').modal('show');
        },
        async editClick(data, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            this.ModalTitle = 'Edit Aktifa: ';

            this.AssetId = data.asset_id;
            this.AssetName = data.asset_name;
            this.AssetType = data.asset_type_id;
            this.AssetTypeData = await this.$globalfunc.globalTypeLookupDropdown("daftar_aktiva");
            this.AssetStatus = data.asset_status_id;
            this.AssetStatusData = await this.$globalfunc.globalStatusLookupDropdown("active_not_active");
            this.AssetPurchaseDate = moment(data.asset_purchase_date).format('YYYY-MM-DD');
            this.AssetPurchaseValue = data.asset_purchase_value;
            this.AssetValue = data.asset_value;
            this.Depreciable = data.is_depreciable,
            this.DepreciationPeriod = data.depreciation_period;
            this.DepreciationPercentageYearly = data.depreciation_percentage_yearly;

            this.onChangeDepreciable();

            this.SaveType = 'Edit';
            document.getElementById('AssetStatus').disabled = false;
            document.getElementById('AssetPurchaseValue').readOnly = true;
            document.getElementById('AssetValue').readOnly = true;
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Asset: ';
            }

            window.$('#assetModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            
            if(this.AssetName == '' || this.AssetName == null){
                this.errorShow('errorAssetName');
            }
            if(this.AssetPurchaseDate == null || this.AssetPurchaseDate.toString() == ''){
                this.errorShow('errorAssetPurchaseDate');
            }
            if(this.AssetPurchaseValue.toString() == '' || this.AssetPurchaseValue.toString() == null || this.AssetPurchaseValue <= 0){
                this.errorShow('errorAssetPurchaseValue');
            }
            if(this.AssetValue.toString() == '' || this.AssetValue.toString() == null || this.AssetValue <= 0){
                this.errorShow('errorAssetValue');
            }
            if(this.Depreciable){
                if(this.DepreciationPeriod == null || this.DepreciationPeriod.toString() == '' || this.DepreciationPeriod <= 0){
                    this.errorShow('errorDepreciationPeriod');
                }
                if(this.DepreciationPercentageYearly == null || this.DepreciationPercentageYearly.toString() == '' || this.DepreciationPercentageYearly <= 0){
                    this.errorShow('errorDepreciationPercentageYearly');
                }
            }
        },
        saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                if(!this.Depreciable && this.SaveType == 'Add'){
                    this.DepreciationPeriod = null;
                    this.DepreciationPercentageYearly = null;
                }else{
                    this.DepreciationPeriod = parseInt(this.DepreciationPeriod);
                    this.DepreciationPercentageYearly = parseFloat(this.DepreciationPercentageYearly);
                }

                const assetData = {
                    asset_type_id: this.AssetType,
                    asset_status_id: this.AssetStatus,
                    asset_name: this.AssetName,
                    asset_purchase_date: this.AssetPurchaseDate,
                    asset_purchase_value: parseFloat(this.AssetPurchaseValue),
                    asset_value: parseFloat(this.AssetValue),
                    is_depreciable: this.Depreciable,
                    depreciation_period: this.DepreciationPeriod,
                    depreciation_percentage_yearly: this.DepreciationPercentageYearly
                };

                //Add
                this.$loading(true);
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : assetData
                    }

                    assetService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#assetModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.AssetId,
                        data : assetData
                    }

                    assetService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#assetModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
