import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class AccountingReportService {
    readAccountingReportQuery(){
        var query = `
            query ($status:[String!], $startDate:Datetime, $endDate:Datetime) {
                GetAccountingReports (Statuses:$status, StartDate:$startDate, EndDate:$endDate) {
                    created_at
                    last_update
                    accounting_report_id
                    status
                    reporting_date
                    approved_date
                    attachments
                    notes
                    created_by
                    approved_by
                }
            }
        `;
        return query;
    }

    async addQuery(variables){
        var query = gql`
            mutation ($data:NewAccountingReport!) {
                CreateAccountingReport (NewAccountingReport:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!, $data:NewAccountingReport!){
            UpdateAccountingReport(AccountingReportID:$id, NewAccountingReport:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        var query = gql`mutation($id:Int!, $data:NewAccountingReport!){
            UpdateAccountingReport(AccountingReportID:$id, NewAccountingReport:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new AccountingReportService();