<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Depresiasi Aktifa</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <asset-depreciation-grid :key="gridReload" :deleteClick="deleteClick" :editClick="editClick"/>
                <asset-depreciation-form ref="assetDepreciationForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import assetDepreciationService from '../AssetDepreciation/Script/AssetDepreciationServices.js';
import assetDepreciationGrid from '../AssetDepreciation/Grid/AssetDepreciationGrid.vue';
import assetDepreciationForm from '../AssetDepreciation/Component/AssetDepreciationForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'AssetDepreciation',
    components: {
        'asset-depreciation-grid': assetDepreciationGrid,
        'asset-depreciation-form': assetDepreciationForm
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Aktifa');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
            this.$refs.assetDepreciationForm.addClick();
        },
        editClick(assetDepreciationData, view){
            this.$refs.assetDepreciationForm.editClick(assetDepreciationData, view);
        },
        deleteClick(data){
            this.$swal(sweet_alert.delete).then((result) => {
                var assetDepreciationData = {
                    asset_depreciation_date: data.asset_depreciation_date,
                    asset_id: data.asset_id,
                    status: "Delete",
                    asset_name: data.asset_name,
                    asset_depreciation_percentage: parseFloat(data.asset_depreciation_percentage),
                    asset_depreciation_value: parseFloat(data.asset_depreciation_value),
                    notes: data.notes,
                };
                const variables = {
                        id : data.asset_id,
                        data : assetDepreciationData
                    }
                if (result.isConfirmed == true) {
                    assetDepreciationService.editQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.errorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>